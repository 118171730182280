<template>
    <el-container>
        <el-header>
            <div class="index-one" @click="homeClick">
                <el-image :src="require('@/assets/logo.png')" alt=""></el-image>
                <div class="index-title">驱动型医院绩效管理平台</div>
            </div>
            <div class="newadd">
                <!-- <div class="index-two">
          <el-input
            placeholder="业务名称"
            prefix-icon="el-icon-search"
            class="index-search"
          >
          </el-input>
        </div> -->
                <div style="position: relative; right: 30px; cursor: pointer" @click="homeClick">
                    <i class="el-icon-s-home" style="font-size: 30px"></i>
                </div>
                <div>
                    <span class="iconfont icon-shujukanban"></span>
                </div>
                <div>
                  <span class="iconfont icon-weibiaoti3" @click="reportStat"></span>
                </div>
                <div @mouseover="nameMouseover" @mouseleave="nameMouseleave">
                    <!-- <div v-if="type == 0" class="index-name">
                        {{ hospitalName }}，{{ administrators }}管理员，{{ nickName }}
                    </div>
                    <div v-else-if="type == 1" class="index-name">
                        {{ departmentName }}，{{ administrators }}管理员，{{ nickName }}
                    </div> -->

                    <div v-if="departmentId !== 'null'" class="index-name">
                        {{ departmentName }}，科室管理员，{{ nickName }}
                    </div>
                    <div v-else class="index-name">
                        {{ hospitalName }}，医院管理员，{{ nickName }}
                    </div>
                </div>
            </div>
        </el-header>
        <!-- 点击顶部名称退出框 -->
        <transition name="el-zoom-in-top">
            <div v-show="show2" class="transition-box" @mouseleave="nameMouseleave" @mouseover="nameMouseover">
                <div class="index-setting">手机号：{{ phone }}</div>
                <div class="index-setting">
                    数据维护权限：
                    <span v-if="dataAuth == 0">是</span>
                    <span v-else>否</span>
                </div>
<!--                <div class="index-setting">-->
<!--                    绩效权限：-->
<!--                    <span v-if="performanceAuth == 0">是</span>-->
<!--                    <span v-else>否</span>-->
<!--                </div>-->
                <div class="index-setting" @click="editPassword">
                    <i class="el-icon-unlock"></i>修改密码
                </div>
                <div class="index-setting">关于驱动型医院绩效管理平台</div>
                <div class="index-setting" @click="Privacypolicy">隐私政策</div>
                <div class="index-baby" @click="logout">退出登录</div>
            </div>
        </transition>
        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="EditPassdialogVisible" width="400px" :before-close="EditPassdialogVisibleClose">
            <!-- 初次登陆修改密码 -->
            <el-form :model="EditFirstPassDate" :inline="true" label-width="120px" ref="EditFirstPassDateRef" :rules="EditFirstPassDateRules" v-if="isFirst">
                <el-form-item label="用户名：">
                    <el-input v-model="nickName" disabled></el-input>
                </el-form-item>
                <el-form-item label="设置密码：" prop="newPassword">
                    <el-input type="password" v-model="EditFirstPassDate.newPassword" placeholder="请输入设置密码"></el-input>
                </el-form-item>
                <el-form-item label="请重复密码：" prop="newPasswordd">
                    <el-input type="password" v-model="EditFirstPassDate.newPasswordd" placeholder="请输入重复密码"></el-input>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button type="primary" @click="editFirstPassSave">保存</el-button>
                    </div>

                </el-form-item>
            </el-form>
            <!-- 不是初次登陆直接修改密码 -->
            <el-form :model="EditPassDate" :inline="true" label-width="120px" ref="EditPassDateRef" :rules="EditPassDateRules" v-else>
                <el-form-item label="用户名：">
                    <el-input v-model="nickName" disabled></el-input>
                </el-form-item>
                <el-form-item label="原密码：" prop="oldPassword">
                    <el-input type="password" v-model="EditPassDate.oldPassword" placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="newPassword">
                    <el-input type="password" v-model="EditPassDate.newPassword" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="请重复密码：" prop="newPasswordd">
                    <el-input type="password" v-model="EditPassDate.newPasswordd" placeholder="请输入重复密码"></el-input>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button type="primary" @click="editPassSave">保存</el-button>
                    </div>

                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击隐私政策弹出框 -->
        <el-dialog title="隐私政策" :visible.sync="PrivacydialogVisible" width="575px">
            <div>
                Cookie具有许多用途，例如：Cookie可以记住您的登录凭据，这样您就不必在每次登录服务时都输入这些凭据。Cookie有助于我们查看您访问的是哪些页面和功能，以及在这些页面上花费的时间。了解此类信息后，我们就能更有效地改善服务并为您提供更好的体验。
                访问和使用我们的服务，即表示您同意在您的设备上存储Cookie、其他本地存储技术、信标和其他信息；同意我们访问此类
                Cookie、本地存储技术、信标和信息。
                个人信息的保护驱动型医院绩效管理平台充分尊重您的隐私权，并且不遗余力保护您的个人信息。通常情况下，您不需要提供任何个人信息就可以浏览驱动型医院绩效管理平台的网站。为特定的目的，如果您以自愿填写的方式提供注册或订阅电子信息所需的姓名、性别、证件种类及证件号、出生年月日、国家、电子邮件信箱、电话、联系地址和邮政编码、所希望提供的服务或喜好信息、客户代码以及其他类似的个人信息，则表示您已经了解并接受您个人信息的用途，同意驱动型医院绩效管理平台为实现该特定目的使用您的个人信息。驱动型医院绩效管理平台承诺在任何时候、任何情况下都不会出售您的个人信息，驱动型医院绩效管理平台只会在法律允许的范围内使用根据本条款获得的信息。但驱动型医院绩效管理平台可能会根据法律、政府部门的要求向这些部门提供您某些个人信息；或在驱动型医院绩效管理平台有理由认为有必要这样做来保护驱动型医院绩效管理平台、客户或公众时，也可能在尽可能小的范围内公开某些个人信息，在您提供个人信息的时候应该已经预见并同意这种情况的发生。
                适用法律和管辖权
                通过访问本网站及使用通过本网站网址提供的设施和（或）服务,
                即表示您同意该访问及该实施和/或服务的提供受中华人民共和国法律的约束，且您同意受中华人民共和国法院的管辖。
            </div>
        </el-dialog>
<!--      填报状态-->
      <el-dialog title="绩效数据填报状态" :visible.sync="reporStatusdialogVisible" width="80%" top="2vh">
        <div>
          <el-table
              :data="repstattableData"
              border
              center
          >
            <el-table-column type="index" label="序号" width="55" align="center">
            </el-table-column>
            <el-table-column
                prop="name"
                label="科室"
                align="center"
            >
            </el-table-column>
            <el-table-column prop="nightDutyAllowance" label="夜班费" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.nightDutyAllowance"
                    class="activeStatus"
                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
            <el-table-column prop="painfulDelivery" label="阵痛分娩奖励" align="center">
              <template slot-scope="scope">
                                <div  v-if="scope.row.painfulDelivery"
                                    class="activeStatus"
                                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
            <el-table-column prop="qualityControl" label="质控扣罚" align="center">
              <template slot-scope="scope">
                                <div  v-if="scope.row.qualityControl"
                                    class="activeStatus"
                                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
            <el-table-column prop="cancerScreen" label="两癌筛查" align="center">
              <template slot-scope="scope">
                                <div  v-if="scope.row.cancerScreen"
                                    class="activeStatus"
                                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
            <el-table-column prop="medicalInsuranceFund" label="医保基金" align="center">
              <template slot-scope="scope">
                                <div  v-if="scope.row.medicalInsuranceFund"
                                    class="activeStatus"
                                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
            <el-table-column prop="bonusSubsidy" label="奖金补助" align="center">
              <template slot-scope="scope">
                                <div  v-if="scope.row.bonusSubsidy"
                                    class="activeStatus"
                                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
            <el-table-column prop="other" label="其他" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.other"
                    class="activeStatus"
                >√</div>
                                <div v-else
                                    class="noStatus"
                                >×</div>
              </template>
            </el-table-column>

            <el-table-column prop="other" label="体检专项(体检科)" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.otherSpecial"
                     class="activeStatus"
                >√</div>
                <div v-else
                     class="noStatus"
                >×</div>
              </template>
            </el-table-column>

            <el-table-column prop="other" label="体检专项" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.checkup"
                     class="activeStatus"
                >√</div>
                <div v-else
                     class="noStatus"
                >×</div>
              </template>
            </el-table-column>

            <el-table-column prop="other" label="外院化验绩效" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.outCheckWorkload"
                     class="activeStatus"
                >√</div>
                <div v-else
                     class="noStatus"
                >×</div>
              </template>
            </el-table-column>


            <el-table-column prop="personnelExpenditure" label="人员列支" align="center">
              <template slot-scope="scope">
                <div  v-if="scope.row.personnelExpenditure"
                    class="activeStatus"
                >√</div>
                <div v-else
                    class="noStatus"
                >×</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        return {
          repstattableData:[],
          reporStatusdialogVisible:false,
            // 是否是第一次登录
            isFirst: false,
            // 医生管理员还是科室管理员
            administrators: "",
            // 手机号
            phone: window.sessionStorage.getItem("phone"),
            // 数据维护权限
            dataAuth: window.sessionStorage.getItem("dataAuth"),
            // 绩效权限
            performanceAuth: window.sessionStorage.getItem("performanceAuth"),
            // 医院名称
            hospitalName: window.sessionStorage.getItem("hospitalName"),
            // 科室名称
            departmentName: window.sessionStorage.getItem("departmentName"),
            type: window.sessionStorage.getItem("type"),
            departmentId: window.sessionStorage.getItem("departmentId"),
            nickName: window.sessionStorage.getItem("nickName"),
            // 鼠标移入名称
            show2: false,
            // 修改密码弹出框
            EditPassdialogVisible: false,
            // 隐私政策弹出框
            PrivacydialogVisible: false,
            // 修改密码数据
            EditPassDate: {
                oldPassword: "",
                newPassword: "",
                newPasswordd: "",
            },
            // 初次登陆修改密码
            EditFirstPassDate: {
                newPassword: "",
                newPasswordd: "",
            },
            // 初次登陆修改密码正则
            EditFirstPassDateRules: {
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                ],
                newPasswordd: [
                    {
                        required: true,
                        message: "请重新输入新密码",
                        trigger: "blur",
                    },
                ],
            },
            // 修改密码正则
            EditPassDateRules: {
                oldPassword: [
                    {
                        required: true,
                        message: "请输入原密码",
                        trigger: "blur",
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                ],
                newPasswordd: [
                    {
                        required: true,
                        message: "请重新输入新密码",
                        trigger: "blur",
                    },
                ],
            },
            // 鼠标移入移除标志
            mouseBiaozhi: "",
        };
    },
    created() {
        this.winty();
        this.isFirs();
    },
    methods: {
      async fillInStatus() {
        let { data: res } = await this.$axios.fillInStatus();
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        }else if (res.code == 200) {
          this.repstattableData = res.rows;
        } else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      },
      // 点击填报状态
      reportStat(){
this.reporStatusdialogVisible=true
        this.fillInStatus()
      },
        EditPassdialogVisibleClose() {
            this.$nextTick(() => {
                if (this.isFirst) {
                    this.$refs.EditFirstPassDateRef.clearValidate();
                } else {
                    this.$refs.EditPassDateRef.clearValidate();
                }
            });

            this.EditPassdialogVisible = false;
        },
        winty() {
            if (window.sessionStorage.getItem("type") == 0) {
                this.administrators = "医院";
                this.isDoctore = true;
            } else {
                this.administrators = "科室";
                this.isDoctore = false;
            }
        },
        //是否是第一次登录 0 是  1 不是
        isFirs() {
            if (window.sessionStorage.getItem("isFirst") == 0) {
                this.isFirst = true;
            } else {
                this.isFirst = false;
            }
        },
        // 点击小房子图标
        homeClick() {
            this.$router.push("/index");
        },
        // 鼠标移入名称
        nameMouseover() {
            this.show2 = true;
        },
        nameMouseleave() {
            this.show2 = false;
        },
        // 修改密码
        editPassword() {
            this.EditPassdialogVisible = true;
        },
        // 点击隐私政策
        Privacypolicy() {
            this.PrivacydialogVisible = true;
        },
        //退出登录
        async logout() {
            let { data: res } = await this.$axios.forceLogout();
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "退出登录失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: "退出登录成功",
                    type: "success",
                });
                window.sessionStorage.clear();
                window.localStorage.clear();
                this.$router.push("/login");
            }
        },
        // 初次登陆修改密码的保存
        editFirstPassSave() {
            this.$refs.EditFirstPassDateRef.validate(async (valid) => {
                if (valid) {
                    if (
                        this.EditFirstPassDate.newPassword ===
                        this.EditFirstPassDate.newPasswordd
                    ) {
                        this.EditPassdialogVisible = false;
                        let data = _qs.stringify({
                            newPassword: this.EditFirstPassDate.newPassword,
                        });
                        let { data: res } = await this.$axios.updateFirstPwd(
                            data
                        );
                        // console.log(res);
                        if (res.code == 401) {
                            this.$router.push("/login");
                        } else if (res.code == 500) {
                            this.$message({
                                message: "修改密码失败",
                                type: "error",
                            });
                        } else if (res.code == 200) {
                            this.$message({
                                message: "修改密码成功,请重新登录",
                                type: "success",
                            });
                            this.$router.push("/login");
                        }
                    } else {
                        this.$message({
                            message: "新密码不一致",
                            type: "error",
                        });
                    }
                }
            });
        },
        // 点击修改密码的保存
        editPassSave() {
            this.$refs.EditPassDateRef.validate(async (valid) => {
                if (valid) {
                    if (
                        this.EditPassDate.newPassword ===
                        this.EditPassDate.newPasswordd
                    ) {
                        let data = _qs.stringify({
                            oldPassword: this.EditPassDate.oldPassword,
                            newPassword: this.EditPassDate.newPassword,
                        });
                        let { data: res } = await this.$axios.updatePwd(data);
                        // console.log(res);
                        if (res.code == 401) {
                            this.$router.push("/login");
                        } else if (res.code == 500) {
                            this.$message({
                                message: res.msg,
                                type: "error",
                            });
                        } else if (res.code == 200) {
                            this.EditPassdialogVisible = false;
                            this.$message({
                                message: "修改密码成功,请重新登录",
                                type: "success",
                            });
                            this.$router.push("/login");
                        }
                    } else {
                        this.$message({
                            message: "新密码不一致",
                            type: "error",
                        });
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-header {
    position: relative;
    width: 100%;
    height: 80px !important;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    justify-content: space-between;
    .index-title {
        font-size: 30px;
    }
    .index-one {
        display: flex;
        position: relative;
        align-items: center;
    }
    .index-one:hover {
        cursor: pointer;
    }

    .el-image__inner {
        width: 55%;
    }
    /deep/ .el-input--prefix .el-input__inner {
        border-radius: 20px;
    }
    .index-two {
        margin-left: 50px;
        margin-right: 50px;
    }
    .icon-shujukanban {
        font-size: 24px;
        margin-right: 30px;
    }
}

.newadd {
    display: flex;
    align-items: center;
}

.index-name {
    cursor: pointer;
    padding: 20px 0;
}

.transition-box {
    text-align: center;
    background: white;
    height: 250px;
    position: absolute;
    right: 10px;
    top: 59px;
    width: 200px;
    padding: 30px;
    font-size: 14px;
    box-shadow: 2px 1px 6px 0px #ccc;
    color: black;
    z-index: 9;
    .index-baby {
        cursor: pointer;
    }
    .index-setting {
        cursor: pointer;
        border-bottom: 1px solid #ccc;
        margin-bottom: 15px;
        padding-bottom: 10px;
        .el-icon-unlock,
        .el-icon-user-solid {
            margin-right: 10px;
        }
    }
    > .el-image {
        width: 50px;
        height: 50px;
    }
}
.depar-dia {
    width: 100%;
}

.btnCen {
    button {
        width: 150px;
    }
}

.icon-weibiaoti3{
  font-size: 24px;
  margin-right: 20px;
}

.icon-weibiaoti3:hover,.icon-shujukanban:hover{
  cursor: pointer;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: darkred;
}
</style>
